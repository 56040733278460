import cookie from 'cookie'
import { FEATURE_FLAG_COOKIE_NAME, FEATURE_FLAG_HEADER_NAME, flags } from '@lib/feature-flags/constants'
import { deserializeFlagValues } from '@lib/feature-flags/utils'

const initializeFeatures = async (ctx) => {
  const { req, res } = ctx

  //  persist feature flags via Set-Cookie response header
  if (!process.browser) {
    const featureFlagHeaderValue = req
      && req.headers
      && req.headers[FEATURE_FLAG_HEADER_NAME.toLowerCase()]
    if (featureFlagHeaderValue) {
      res.setHeader('Set-Cookie', cookie.serialize(FEATURE_FLAG_COOKIE_NAME, featureFlagHeaderValue,
        {
          maxAge: 31536000, /* 1 year */
          secure: true,
          path: '/',
        }))
    }
  }

  let rawFlagValues = null
  if (!process.browser) {
    rawFlagValues = req && req.headers && req.headers[FEATURE_FLAG_HEADER_NAME.toLowerCase()]

    // if doing local development, read directly from request cookies
    if (process.env.ENV === 'development') {
      rawFlagValues = cookie.parse((req && req.headers && req.headers.cookie) || '')[FEATURE_FLAG_COOKIE_NAME]
    }
  } else {
    rawFlagValues = cookie.parse(document.cookie)[FEATURE_FLAG_COOKIE_NAME]
  }

  const flagValues = deserializeFlagValues(rawFlagValues)

  return {
    [flags.FLAG_FOR_TESTING]: flagValues[flags.FLAG_FOR_TESTING] === 'true',
    [flags.FLAG_COACHES_SEARCH_PRIORITY]: flagValues[flags.FLAG_COACHES_SEARCH_PRIORITY] === 'true',
    [flags.FLAG_MARKETING_OPT_IN_EXP]: flagValues[flags.FLAG_MARKETING_OPT_IN_EXP],
    [flags.FLAG_AGE_SPECIFIC_GROUP]: flagValues[flags.FLAG_AGE_SPECIFIC_GROUP] === 'true',
    [flags.FLAG_MARKETING_OPT_IN_WAITLIST]: flagValues[flags.FLAG_MARKETING_OPT_IN_WAITLIST] === 'true',
    [flags.FLAG_OPTIMIZED_COACHES_SEARCH_PAGE]: flagValues[flags.FLAG_OPTIMIZED_COACHES_SEARCH_PAGE] === 'true',
    [flags.FLAG_CUSTOM_PAGE]: flagValues[flags.FLAG_CUSTOM_PAGE] === 'true',
  }
}

export default initializeFeatures
